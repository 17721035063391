import styles from './Header.module.css';
import Arrow from "./Icons/Arrow/Arrow";
import HeaderLogo from "./Icons/Logo/HeaderLogo";
import Buttonbar from "./Buttonbar/Buttonbar";
import MailIcon from "./Icons/MailIcon/MailIcon";
import Phone from "./Icons/Phone/Phone";
import { getPopupParams } from '../../utils/popup';
import { RequestTypes } from '../../const/const';

const Header = ({ onPopupButtonClick }) => {
  const abstracts = [
    'Комплексно',
    'Качественно',
    'Конфиденциально'
  ];

  return(
    <div className={styles.container}>
      <div className={styles.leftSide}>
        <div className={styles.logoPanel}>
          <HeaderLogo />
          <div className={styles.nameBlock}>
            <div className={styles.name}>Защитный комплекс</div>
            {abstracts.map((item) => {
              return(
                <div className={styles.abstractBlock} key={item}>
                  <Arrow />
                  <div className={styles.abstract}>{item}</div>
                </div>
              );
            })}
          </div>
        </div>
        <Buttonbar />
      </div>
      <div className={styles.contacts}>
        <div className={styles.mailBlock}>
          <MailIcon />
          <a className={styles.mail} href="mailto:fds@zc-59.ru">fds@zc-59.ru</a>
        </div>
        <div
          className={styles.phoneBlock}
          onClick={() => onPopupButtonClick(getPopupParams(RequestTypes.CONSULTATION))}
        >
          <Phone />
          <div className={styles.phone}>+7 (342) 2-787-687</div>
        </div>
      </div>
    </div>
  );
}

export default Header;
