const AboutMainLine = () => {
  return(
    <svg width="1212.000000" height="27.000000" viewBox="0 0 1212 27" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <radialGradient gradientTransform="translate(14 13.5) rotate(90) scale(13.5 14)" cx="0.000000" cy="0.000000"
                        r="1.000000" id="paint_radial_23_85_0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFFFFF" stopOpacity="0.501961"/>
          <stop offset="1.000000" stopColor="#999999" stopOpacity="0.000000"/>
        </radialGradient>
      </defs>
      <line id="Line 43" x1="1212.000000" y1="12.500000" x2="12.000000" y2="12.500000" stroke="#FFFFFF"
            strokeOpacity="1.000000" strokeWidth="1.000000"/>
      <ellipse id="Ellipse 13" cx="14.000000" cy="13.500000" rx="14.000000" ry="13.500000"
               fill="url(#paint_radial_23_85_0)" fillOpacity="1.000000"/>
    </svg>
  )
}

export default AboutMainLine;