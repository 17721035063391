export const FormField = ({ params }) => {
  if (params.type === 'textarea') {
    return (
      <textarea
        className={params.className}
        style={{
          height: '96px',
          paddingTop: '10px'
        }}
        onChange={(e) => params.onChange({ fieldName: params.fieldName, value: e.target.value})}
        placeholder={params.placeholder}
      />
    )
  }

  return (
    <input
      className={params.className}
      onChange={(e) => params.onChange({ fieldName: params.fieldName, value: e.target.value})}
      placeholder={params.placeholder}
      required={params.required}
    />
  )
};
