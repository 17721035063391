import styles from './Service.module.css';
import ServiceMainLine from './ServiceMainLine/ServiceMainLine';
import ServiceAdditionalLine from './ServiceAdditionalLine/ServiceAdditionalLine';
import ServiceCard from './ServiceCard/ServiceCard';

const Services = () => {
  const services = [
    {
      id: 1,
      label: 'Проектирование\nсистем\nпожарной защиты',
      description: 'Проанализируем риски, \n' +
        'выберем подходящие технологии,\n' +
        'разработаем детализированные\n' +
        'планы и технические \n' +
        'спецификации',
      imgSrc: 'projecting.png'
    },
    {
      id: 2,
      label: 'Монтаж\nзапроектированных\nсистем защиты',
      description: 'Установим и настроим \n' +
        'оборудование, \n' +
        'обучим персонал правилам\n' +
        'эксплуатации системы',
      imgSrc: 'installing.png'
    },
    {
      id: 3,
      label: 'Техническое\nобслуживание\nсистем защиты',
      description: 'Определим виды, \n' +
        'периодичность и объем работ, \n' +
        'организуем периодическую \n' +
        'проверку работоспособности, \n' +
        'настроим параметры \n' +
        'срабатывания оповещений \n' +
        'и выявим причины\n' +
        'неисправностей',
      imgSrc: 'maintenance.png'
    },
    {
      id: 4,
      label: 'Адаптация систем\nпод архитектурные\nрешения',
      description: 'Настроим и модифицируем\n' +
        'систему в соответствии \n' +
        'с конкретными архитектурными \n' +
        'требованиями и условиями\n' +
        'эксплуатации',
      imgSrc: 'adaptation.png'
    },
    {
      id: 5,
      label: 'Восстановление\nтехнической\nдокументации',
      description: 'Восстановим или создадим \n' +
        'с нуля утерянные \n' +
        'или поврежденные \n' +
        'документы, необходимые \n' +
        'для эксплуатации \n' +
        'и обслуживания систем \n' +
        'пожарной защиты',
      imgSrc: 'recovery.png'
    },
  ]

  const systems = [
    'систему противодымной вентиляции',
    'систему видеонаблюдения',
    'систему контроля и управления доступом'
  ]

  return(
    <div className={styles.container} id={'services'}>
      <div className={styles.title}>
        <div>услуги</div>
        <ServiceMainLine />
      </div>
      <div className={styles.services}>
        {services.map((item) => {
          return(
            <ServiceCard
              key={item.id}
              label={item.label}
              description={item.description}
              imgSrc={item.imgSrc}
            />
          );
        })}
      </div>
      <div className={styles.additional}>
        <div className={styles.info}>
          <div className={styles.additionalTitle}>Дополнительно в рамках комплекса работ мы готовы запроектировать,
            установить и обслуживать:</div>
          {systems.map((item) => {
            return(
              <div className={styles.system} key={item}>
                <ServiceAdditionalLine />
                <div>{item}</div>
              </div>
            )
          })}
        </div>
        <img src={'/img/services/cameras.jpg'} alt={'img'} className={styles.camerasImg}/>
      </div>
    </div>
  );
}

export default Services
