const RightLine = () => {
  return(
    <svg width="263" height="14" viewBox="0 0 263 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="263" y1="7.5" x2="7" y2="7.5" stroke="#EA6119"/>
      <circle cx="7" cy="7" r="7" transform="matrix(-1 0 0 1 14 0)" fill="url(#paint0_radial_0_1)"/>
      <defs>
        <radialGradient id="paint0_radial_0_1" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                        gradientTransform="translate(7 7) rotate(90) scale(7)">
          <stop stopColor="#EA6119"/>
          <stop offset="1" stopColor="white" stopOpacity="0.17"/>
        </radialGradient>
      </defs>
    </svg>

  );
}

export default RightLine;