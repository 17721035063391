const Point = () => {
  return(
    <svg width="24.0" height="24.0" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#FFFFFF">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"/>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"/>
      <g id="SVGRepo_iconCarrier">
        <circle cx="12" cy="12" r="2" fill="#FFFFFF"/>
      </g>
    </svg>
  );
}

export default Point;