import styles from './App.module.css';
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";
import Services from './components/Services/Services';
import About from './components/About/About';
import Consultation from './components/Consultation/Consultation';
import Calculation from './components/Calculation/Calculation';
import FAQ from './components/FAQ/FAQ';
import Footer from './components/Footer/Footer';
import Customers from './components/Customers/Customers';
import {useState} from 'react';
import Licenses from './components/Licenses/Licenses';
import RequestPopup from './common/components/RequestPopup/RequestPopup';

const App = () => {
  const [popup, setPopup] = useState({
    isShow: false,
    onClose: () => setPopup({
      ...popup,
      params: null,
      isShow: false
    })
  });

  const onPopupButtonClick = (params) => {
    setPopup({
      ...popup,
      isShow: true,
      params
    })
  }

  return (
    <div className={styles.container}>
      {popup?.isShow && (
        <RequestPopup
          type={popup.params.type}
          title={popup.params.title}
          subtitle={popup.params.subtitle}
          img={popup.params.img}
          alt={popup.params.alt}
          action={popup.params.action}
          fields={popup.params.fields}
          onClose={popup.onClose}
        />
      )}
      <Header onPopupButtonClick={onPopupButtonClick} />
      <div className={styles.content}>
        <Main onPopupButtonClick={onPopupButtonClick} />
        <Services onPopupButtonClick={onPopupButtonClick} />
        <About />
        <Licenses />
        <Consultation />
        <Customers />
        <Calculation onPopupButtonClick={onPopupButtonClick} />
        <FAQ />
        <Footer />
      </div>
    </div>
  );
}

export default App;
