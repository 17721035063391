const DownArrow = ({ isRotated }) => {
  return(
    <svg width="25.004059" height="13.001953" viewBox="0 0 25.0041 13.002" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink"
         style={{ transform: isRotated ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.5s' }}
    >
      <defs/>
      <path id="Vector" d="M0.5 0.5L12.5 12.5L24.5 0.5" stroke="#EA6119" strokeOpacity="1.000000"
            strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"
      />
    </svg>
  );
}

export default DownArrow;
