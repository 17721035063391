import styles from './Main.module.css'
import Roulette from "./Roulette/Roulette";
import { getPopupParams } from '../../utils/popup';
import { RequestTypes } from '../../const/const';

const Main = ({ onPopupButtonClick }) => {
  return(
    <div className={styles.container}>
      <div className={styles.content}>
        <Roulette />
        <img src={"/img/main.jpg"} alt={'img'} className={styles.mainImg}/>
        <div className={styles.slogan}>Под «ключ» | Качественно | С гарантией</div>
        <div
          className={styles.more}
          onClick={() => onPopupButtonClick(getPopupParams(RequestTypes.CONSULTATION))}
        >
          Узнать больше
        </div>
      </div>
    </div>
  );
}

export default Main;
