import styles from './Licenses.module.css';
import ISO from '../../common/pdfs/ISO.pdf';
import License from '../../common/pdfs/License.pdf';
import SRO from '../../common/pdfs/SRO.pdf';
import LeftLine from './svg/LeftLine/LeftLine';
import RightLine from './svg/RightLine/RightLine';


const Licenses = () => {
  return(
    <div className={styles.container}>
      <LeftLine/>
      <div className={styles.content}>
        <a className={styles.item} href={License} target="_blank" rel="noreferrer">
          <img className={styles.img} src={'/img/licenses/License.jpg'} alt={'License'}/>
        </a>
        <a className={styles.item} href={SRO} target="_blank" rel="noreferrer">
          <img className={styles.img} src={'/img/licenses/SRO.jpg'} alt={'SRO'}/>
        </a>
        <a className={styles.item} href={ISO} target="_blank" rel="noreferrer">
          <img className={styles.img} src={'/img/licenses/ISO.jpg'} alt={'ISO'}/>
        </a>
      </div>
      <RightLine/>
    </div>
  );
}

export default Licenses
