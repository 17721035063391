import styles from './NotifyPopup.module.css';
import { useEffect } from 'react';

const NotifyPopup = ({
  title,
  message,
  onClose
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 10000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return(
    <div className={styles.container}>
      <div className={`${styles.content} ${styles.result}`}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{message}</div>
        </div>
        <div className={styles.buttonBlock}>
          <div className={styles.button} onClick={onClose}>OK</div>
        </div>
      </div>
    </div>
  );
}

export default NotifyPopup;
