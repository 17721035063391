import styles from './Consultation.module.css';
import { send } from '../../api/api';
import { useState } from 'react';
import { FormField } from '../../common/components/RequestPopup/FormField/FormField';
import { RequestTypes } from '../../const/const';
import Spinner from '../../common/components/Spinner/Spinner';
import NotifyPopup from '../../common/components/NotifyPopup/NotifyPopup';

const Consultation = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState({
    fields: [
      {
        fieldName: 'name',
        type: 'input',
        value: '',
        placeholder: 'Имя',
        required: true
      },
      {
        fieldName: 'phone',
        type: 'input',
        value: '',
        placeholder: 'Телефон',
        required: true
      },
    ]
  });
  const [sendResult, setSendResult] = useState({
    isShow: false,
    isSuccess: false,
    title: '',
    message: ''
  });

  const onChangeHandler = ({ fieldName, value }) => {
    const fields = data.fields.map(item => {
      if (item.fieldName === fieldName) {

        return { ...item, value}
      }

      return item;
    })

    setData({ ...data, fields});
  };

  const onSubmitClick = () => {
    try {
      const sendData = data.fields.reduce((acc, item) => {
        acc[item.fieldName] = item.value;

        return acc;
      }, {})
      sendData.type = RequestTypes.CONSULTATION;

      setIsFetching(true);

      send(sendData).then(() => {
        setIsFetching(false);
        setSendResult({
          isShow: true,
          isSuccess: true,
          title: 'Запрос отправлен!',
          message: 'Благодарим вас за интерес к нашей компании. В ближайшее время мы свяжемся с вами для уточнения деталей'
        });
      });
    } catch (e) {
      setIsFetching(false);
      setSendResult({
        isShow: true,
        isSuccess: false,
        title: 'Ошибка при отправке запроса',
        message: e.message || ''
      })
    }
  }

  if (sendResult.isShow) {
    return (
      <NotifyPopup
        title={sendResult.title}
        message={sendResult.message}
        isFetching={isFetching}
        onClose={() => setSendResult({
          isShow: false,
          isSuccess: false,
          title: '',
          message: ''
        })}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.panel}>
        <div className={styles.titleBlock}>
          <div className={styles.title}>
            Нужна консультация?
          </div>
          <div className={styles.subtitle}>
            Оставьте заявку – и мы перезвоним.
          </div>
        </div>
        <div className={styles.request}>
          <div className={styles.inputBlock}>
            <div className={styles.inputBlock}>
              {data.fields.map(item => {
                return (
                  <FormField
                    key={item.fieldName}
                    params={{
                      ...item,
                      className: styles.input,
                      onChange: onChangeHandler
                    }}
                  />
                )
              })}
            </div>
          </div>
          <div
            className={styles.button}
            onClick={onSubmitClick}
          >
            {isFetching ? <Spinner /> : 'Заказать звонок'}
          </div>
          <div className={styles.form}>Отправляя форму вы соглашаетесь на обработку персональных данных. </div>
        </div>
      </div>
    </div>
  );
}

export default Consultation;
