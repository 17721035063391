const Years = () => {
  return(
    <svg width="115" height="60" viewBox="0 0 115 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.8" d="M11.8047 1.98438H21.8047V58H9.8125V17.2188H0.203125V9.60156C1.92188 9.39323 3.4974
      8.98958 4.92969 8.39062C6.36198 7.76562 7.46875 7.10156 8.25 6.39844C9.05729 5.69531 9.73438 5.01823 10.2812
      4.36719C10.8542 3.6901 11.2578 3.11719 11.4922 2.64844L11.8047 1.98438ZM61.8438 28.3906C62.1042 28.4948 62.4557
      28.651 62.8984 28.8594C63.3672 29.0417 64.1745 29.5104 65.3203 30.2656C66.4661 30.9948 67.4688 31.8411 68.3281
      32.8047C69.2135 33.7682 70.0208 35.1094 70.75 36.8281C71.4792 38.5208 71.8438 40.3828 71.8438 42.4141C71.8438
      46.9974 69.9297 50.9427 66.1016 54.25C62.2995 57.5573 57.2734 59.2109 51.0234 59.2109C48.3672 59.2109 45.8021
      58.8984 43.3281 58.2734C40.8802 57.6745 38.875 56.9453 37.3125 56.0859C35.75 55.2266 34.3698 54.3802 33.1719
      53.5469C32 52.6875 31.1536 51.9323 30.6328 51.2812L29.8125 50.3828L37.0391 42.8047C37.1432 42.9609 37.3125
      43.1823 37.5469 43.4688C37.7812 43.7292 38.3151 44.2109 39.1484 44.9141C39.9818 45.5911 40.8542 46.2031 41.7656
      46.75C42.7031 47.2708 43.888 47.7396 45.3203 48.1562C46.7786 48.5729 48.276 48.7812 49.8125 48.7812C52.9115
      48.7812 55.2812 48.1042 56.9219 46.75C58.5885 45.3958 59.4219 43.6771 59.4219 41.5938C59.4219 39.5104 58.6016
      37.7917 56.9609 36.4375C55.3464 35.0833 53.1068 34.4062 50.2422 34.4062H44.2266V24.4062H49.8125C52.1562 24.4062
      54.0052 23.7812 55.3594 22.5312C56.7396 21.2812 57.4297 19.6406 57.4297 17.6094C57.4297 15.7344 56.7526 14.1979
      55.3984 13C54.0443 11.8021 52.1823 11.2031 49.8125 11.2031C47.7812 11.2031 45.8672 11.6458 44.0703 12.5312C42.2995
      13.4167 41.0234 14.276 40.2422 15.1094L39.0312 16.3984L31.8438 9.21094C32 9.0026 32.2344 8.71615 32.5469
      8.35156C32.8594 7.96094 33.6016 7.27083 34.7734 6.28125C35.9714 5.29167 37.2474 4.44531 38.6016 3.74219C39.9557
      3.01302 41.7526 2.34896 43.9922 1.75C46.2318 1.125 48.5755 0.8125 51.0234 0.8125C56.6745 0.8125 61.2188 2.3099
      64.6562 5.30469C68.0938 8.27344 69.8125 11.9714 69.8125 16.3984C69.8125 18.0391 69.526 19.5755 68.9531
      21.0078C68.4062 22.4141 67.7422 23.5599 66.9609 24.4453C66.2057 25.3307 65.4375 26.0859 64.6562
      26.7109C63.901 27.3099 63.224 27.7396 62.625 28L61.8438 28.3906ZM100.633
      53.1953H91.4531V39.6016H77.8594V29.6016H91.4531V16.0078H100.633V29.6016H114.266V39.6016H100.633V53.1953Z"
            fill="url(#paint0_linear_165_186)"/>
      <defs>
        <linearGradient id="paint0_linear_165_186" x1="13.3146" y1="25" x2="113.812" y2="-6.05268"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.536523" stopColor="#EA6119"/>
          <stop offset="1" stopColor="#DC0D00"/>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Years;