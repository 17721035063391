import styles from './Calculation.module.css'
import CalculationMainLine from './CalculationMainLine/CalculationMainLine';
import CostIcon from './CostIcon/CostIcon';
import { RequestTypes } from '../../const/const';
import { getPopupParams } from '../../utils/popup';

const Calculation = ({ onPopupButtonClick }) => {
  const items = [
    {
      label: 'РАСЧЕТ КП',
      info: 'Отправьте нам документацию и получите готовое КП',
      button: 'Получить КП',
      popupParams: getPopupParams(RequestTypes.CALCULATION)
    },
    {
      label: 'ВЫЕЗД НА ОБЪЕКТ',
      info: 'Бесплатно отправим специалиста на объект для осмотра и составления сметы',
      button: 'Заказать выезд',
      popupParams: getPopupParams(RequestTypes.CONSULTATION)
    },
    {
      label: 'ПРИГЛАШЕНИЕ В ТЕНДЕР',
      info: 'Мы открыты к участию в корпоративных тендерах, а также участвуем в закупках по 44-ФЗ и 223-ФЗ',
      button: 'Пригласить в тендер',
      popupParams: getPopupParams(RequestTypes.TENDER)
    },
  ]

  return(
    <div className={styles.container} id={'calculation'}>
      <div className={styles.title}>
        <div>расчеты</div>
        <CalculationMainLine />
      </div>
      <div className={styles.content}>
        {items.map((item) => {
          return(
            <div className={styles.item} key={item.label}>
              <div className={styles.infoBlock}>
                <div className={styles.label}>{item.label}</div>
                <div className={styles.info}>{item.info}</div>
              </div>
              <div
                className={styles.button}
                onClick={() => onPopupButtonClick(item.popupParams)}
              >
                {item.button}
              </div>
            </div>
          );
        })}
        <div className={styles.cost}>
          <CostIcon />
          <div>Стоимость наших работ полностью соответствует высокому уровню компетенций и качеству выполнения работ</div>
        </div>
      </div>
    </div>
  );
}

export default Calculation;
