import styles from './RequestPopup.module.css';
import { useEffect, useState } from 'react';
import CloseIcon from '../../icons/CloseIcon/CloseIcon';
import { FormField } from './FormField/FormField';
import { send } from '../../../api/api';
import NotifyPopup from '../NotifyPopup/NotifyPopup';
import Spinner from '../Spinner/Spinner';

const RequestPopup = ({
  type,
  title,
  subtitle,
  img,
  alt,
  action,
  fields,
  onClose
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState({
    type,
    fields
  });
  const [sendResult, setSendResult] = useState({
    isShow: false,
    isSuccess: false,
    title: '',
    message: ''
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      sendResult.isShow && onClose();
    }, 10000);

    return () => clearTimeout(timer);
  }, [onClose, sendResult.isShow]);

  const onChangeHandler = ({ fieldName, value }) => {
    const fields = data.fields.map(item => {
      if (item.fieldName === fieldName) {

        return { ...item, value}
      }

      return item;
    })

    setData({ ...data, fields});
  };

  const handleActionButtonClick = () => {
    try {
      const sendData = data.fields.reduce((acc, item) => {
        acc[item.fieldName] = item.value;

        return acc;
      }, {})
      sendData.type = type;

      setIsFetching(true);

      send(sendData).then(() => {
        setIsFetching(false);
        setSendResult({
          isShow: true,
          isSuccess: true,
          title: 'Запрос отправлен!',
          message: 'Благодарим вас за интерес к нашей компании. В ближайшее время мы свяжемся с вами для уточнения деталей'
        });
      });
    } catch (e) {
      setIsFetching(false);
      setSendResult({
        isShow: true,
        isSuccess: false,
        title: 'Ошибка при отправке запроса',
        message: e.message || ''
      })
    }
  };

  if (sendResult.isShow) {
    return (
      <NotifyPopup
        title={sendResult.title}
        message={sendResult.message}
        isFetching={isFetching}
        onClose={onClose}
      />
    )
  }

  return(
    <div className={styles.container}>
      <img src={img} alt={alt} className={styles.img}/>
      <div className={styles.content}>
        <div className={styles.close} onClick={onClose}>
          <CloseIcon />
        </div>
        <div className={styles.titleBlock}>
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        <div className={styles.inputBlock}>
          {fields.map(item => {
            return (
              <FormField
                key={item.fieldName}
                params={{
                  ...item,
                  className: styles.input,
                  onChange: onChangeHandler
              }}
              />
            )
          })}
        </div>
        <div className={styles.buttonBlock}>
          <div className={styles.button} onClick={handleActionButtonClick}>
            {isFetching ? <Spinner /> : action}
          </div>
          <div className={styles.agreement}>{'Отправляя форму вы соглашаетесь\nна обработку персональных данных.'}</div>
        </div>
      </div>
    </div>
  );
}

export default RequestPopup;
