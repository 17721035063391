const CustomersCount = () => {
  return(
    <svg width="145.507812" height="58.984375" viewBox="0 0 145.508 58.9844" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <linearGradient x1="14.224203" y1="26.183586" x2="137.078140" y2="8.683617" id="paint_linear_23_95_0"
                        gradientUnits="userSpaceOnUse">
          <stop offset="0.536523" stopColor="#EA6119"/>
          <stop offset="1.000000" stopColor="#DC0D00"/>
        </linearGradient>
      </defs>
      <g opacity="0.800000">
        <path id="55+" d="M42.77 38.78C42.81 50.54 34 58.98 21.03 58.98C9 58.98 0.21 51.79 0 41.73L11.93 41.73C12.2
        46.25 16.15 49.31 21.03 49.31C26.81 49.31 30.93 45.13 30.93 39.1C30.93 32.98 26.71 28.75 20.87 28.73C17.44
        28.73 13.96 30.23 12.18 32.63L1.25 30.68L4 0L39.57 0L39.57 10.05L14.14 10.05L12.63 24.62L12.96 24.62C15.01
        21.75 19.43 19.62 24.53 19.62C35.01 19.62 42.81 27.63 42.77 38.78ZM94.33 38.78C94.37 50.54 85.56 58.98 72.59
        58.98C60.56 58.98 51.77 51.79 51.56 41.73L63.49 41.73C63.76 46.25 67.71 49.31 72.59 49.31C78.37 49.31 82.5
        45.13 82.5 39.1C82.5 32.98 78.28 28.75 72.44 28.73C69 28.73 65.52 30.23 63.75 32.63L52.81 30.68L55.56 0L91.13
        0L91.13 10.05L65.7 10.05L64.19 24.62L64.53 24.62C66.58 21.75 70.99 19.62 76.09 19.62C86.58 19.62 94.37 27.63
        94.33 38.78ZM130.46 49.31L120.31 49.31L120.31 34.29L105.27 34.29L105.27 24.12L120.31 24.12L120.31 9.1L130.46
        9.1L130.46 24.12L145.5 24.12L145.5 34.29L130.46 34.29L130.46 49.31Z" fill="url(#paint_linear_23_95_0)"
              fillOpacity="1.000000" fillRule="evenodd"/>
      </g>
    </svg>

  );
}

export default CustomersCount;