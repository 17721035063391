import styles from './ServiceCard.module.css';
import { useState } from 'react';

const ServiceCard = ({ label, imgSrc, description}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.container} onClick={() => setIsOpen(!isOpen)}>
      {isOpen ? (
        <div className={`${styles.content} ${styles.open}`}>
          <div className={`${styles.label} ${styles.bold}`}>{label}</div>
          <div className={styles.description}>{description}</div>
        </div>
      ) : (
        <div className={styles.content}>
          <img src={`/img/services/${imgSrc}`} alt={'img'} className={styles.serviceImg}/>
          <div className={styles.label}>{label}</div>
        </div>
      )}
    </div>
  )
}

export default ServiceCard;
