const Phone = () => {
  return(
    <svg width="25.953918" height="26.000732" viewBox="0 0 25.9539 26.0007"
         fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path id="Vector" d="M15.83 5.58C16.95 5.8 17.98 6.35 18.79 7.16C19.6 7.96 20.14 8.99 20.36
       10.11M15.83 1C18.16 1.25 20.33 2.3 21.99 3.95C23.64 5.61 24.69 7.77 24.95 10.1M23.8
       22.69C23.8 23.01 23.74 23.33 23.61 23.62C23.48 23.91 23.29 24.18 23.06 24.39C22.82
       24.61 22.55 24.77 22.24 24.87C21.94 24.98 21.62 25.01 21.3 24.99C17.77 24.6 14.38 23.4
       11.41 21.47C8.63 19.7 6.29 17.36 4.52 14.58C2.59 11.59 1.38 8.19 1 4.64C0.98 4.33 1.01
       4.01 1.11 3.7C1.22 3.4 1.38 3.12 1.59 2.89C1.81 2.65 2.07 2.47 2.36 2.34C2.65 2.21 2.97
       2.14 3.29 2.14L6.73 2.14C7.28 2.14 7.82 2.33 8.24 2.7C8.67 3.06 8.94 3.56 9.02 4.11C9.16
       5.22 9.43 6.3 9.82 7.34C9.98 7.75 10.01 8.19 9.92 8.62C9.83 9.05 9.61 9.45 9.31 9.76L7.85
       11.21C9.48 14.08 11.86 16.46 14.73 18.09L16.19 16.64C16.5 16.33 16.89 16.12 17.32 16.03C17.75
       15.93 18.2 15.97 18.61 16.12C19.65 16.51 20.73 16.78 21.83 16.92C22.39 17 22.9 17.28 23.26 17.71C23.62
       18.14 23.82 18.69 23.8 19.25L23.8 22.69Z" stroke="#FFFFFF"
            strokeOpacity="1.000000" strokeWidth="2.000000" strokeLinejoin="round"/>
    </svg>
  );
}

export default Phone;