const Arrow = () => {
  return(
    <svg width="6.400024" height="8.000000" viewBox="0 0 6.40002 8" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <path id="Vector" d="M2.8 0.41C1.73 -0.51 0 0.2 0 1.56L0 6.43C0 7.73 1.6 8.46 2.7 7.66L5.75 5.42C6.57 4.83 6.61
       3.69 5.85 3.04L2.8 0.41Z" fill="#EA6119" fillOpacity="1.000000" fillRule="nonzero"/>
    </svg>
  );
}

export default Arrow;