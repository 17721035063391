const MailIcon = () => {
  return(
    <svg width="21.000000" height="17.000000" viewBox="0 0 21 17" fill="none"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <path id="Vector" d="M18.5 0.5C19.6 0.5 20.5 1.4 20.5 2.5L20.5 14.5C20.5 15.6 19.6
       16.5 18.5 16.5L2.5 16.5C1.4 16.5 0.5 15.6 0.5 14.5L0.5 2.5C0.5 1.4 1.4 0.5 2.5 0.5L18.5
       0.5ZM20.5 2.5L10.5 9.5L0.5 2.5" stroke="#FFFFFF" strokeOpacity="1.000000"
            strokeWidth="1.000000" strokeLinejoin="round"/>
    </svg>
  )
}

export default MailIcon;