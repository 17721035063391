import { RequestTypes } from '../const/const';

export const getPopupParams = (type) => {
  if (type === RequestTypes.CALCULATION) {
    return {
      type: RequestTypes.CALCULATION,
      title: 'Расчет стоимости',
      subtitle: 'Отправьте нам документацию\nи получите готовое КП',
      img: '/img/popups/calculation.png',
      alt: 'tender_png',
      action: 'Отправить заявку',
      fields: [
        {
          fieldName: 'name',
          type: 'input',
          value: '',
          placeholder: 'Имя',
          required: true
        },
        {
          fieldName: 'phone',
          type: 'input',
          value: '',
          placeholder: 'Телефон',
          required: true
        },
        {
          fieldName: 'email',
          type: 'input',
          value: '',
          placeholder: ' E-Mail',
          required: true
        },
        {
          fieldName: 'comment',
          type: 'textarea',
          value: '',
          placeholder: 'Комментарий'
        }
      ],
    }
  }

  if (type === RequestTypes.CONSULTATION) {
    return {
      type: RequestTypes.CONSULTATION,
      title: 'Получить консультацию',
      subtitle: 'Оставьте заявку – и мы перезвоним',
      img: '/img/popups/consultation.png',
      alt: 'consultation_png',
      action: 'Заказать звонок',
      fields: [
        {
          fieldName: 'name',
          type: 'input',
          value: '',
          placeholder: 'Имя',
          required: true
        },
        {
          fieldName: 'phone',
          type: 'input',
          value: '',
          placeholder: 'Телефон',
          required: true
        },
      ],
    }
  }

  if (type === RequestTypes.TENDER) {
    return {
      type: RequestTypes.TENDER,
      title: 'Участие в тендере',
      subtitle: 'Мы открыты к участию в корпоративных\nтендерах, а также участвуем в закупках\nпо 44-ФЗ и 223-ФЗ',
      img: '/img/popups/tender.png',
      alt: 'tender_png',
      action: 'Отправить приглашение',
      fields: [
        {
          fieldName: 'name',
          type: 'input',
          value: '',
          placeholder: 'Имя',
          required: true
        },
        {
          fieldName: 'phone',
          type: 'input',
          value: '',
          placeholder: 'Телефон',
          required: true
        },
        {
          fieldName: 'email',
          type: 'input',
          value: '',
          placeholder: ' E-Mail',
          required: true
        },
        {
          fieldName: 'comment',
          type: 'textarea',
          value: '',
          placeholder: 'Комментарий'
        }
      ],
    }
  }

  return null;
}
