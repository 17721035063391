const CloseIcon = () => {
  return(
    <svg width="18.007812" height="18.008179" viewBox="0 0 18.0078 18.0082" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <path id="Vector" d="M17 1L1 17M1 1L17 17" stroke="#0D2233" strokeOpacity="1.000000" strokeWidth="2.000000"
            strokeLinejoin="round" strokeLinecap="round"/>
    </svg>
  );
}

export default CloseIcon;