const LeftArrow = () => {
  return(
    <svg width="10.232788" height="19.465576" viewBox="0 0 10.2328 19.4656" fill="none"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs/>
      <path id="Vector" d="M9.73 0.5L0.49 9.73L9.73 18.96" stroke="#4F5F76" strokeOpacity="1.000000"
            strokeWidth="1.000000" strokeLinejoin="round" strokeLinecap="round"/>
    </svg>
  );
}

export default LeftArrow;