import styles from './Accordion.module.css';
import DownArrow from './DownArrow/DownArrow';
import {useState} from 'react';

const Accordion = ({title, content}) => {
  const [isOpen, setIsOpen] = useState(false);

  return(
    <div className={styles.container}>
      <div
        className={styles.header}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.title}>{title}</div>
        <DownArrow isRotated={isOpen}/>
      </div>
      {isOpen && (
        <div className={styles.dropDown}>{content}</div>
      )}
    </div>
  );
}

export default Accordion;
