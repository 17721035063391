import styles from './Roulette.module.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/autoplay';
import {Autoplay} from 'swiper/modules';


const titles = [
  'Проектирование',
  'Монтаж',
  'Обслуживание',
  'Адаптация'
]

const Roulette = () => {

  return(
    <div className={styles.container}>
      <div style={{
        height: '70px'
      }}>
        <Swiper
          modules={[Autoplay]}
          loop={true}
          width={600}
          spaceBetween={5}
          slidesPerView={1}
          initialSlide={2}
          style={{
            height: '90px',
            overflow: 'hidden'
          }}
          direction={'vertical'}
          autoplay={{
            delay: 3500,
          }}
        >
          {titles.map((item) => {
            return(
              <SwiperSlide
                key={item}
                className="custom-slide"
                style={{
                  width: '600px',
                  height: '64px',
                  color: 'rgb(234, 97, 25)',
                  fontFamily: 'TT Norms bold, sans-serif',
                  fontSize: '64px',
                  fontWeight: '700',
                  lineHeight: '80px',
                  letterSpacing: '0',
                  textAlign: 'left'
                }}
              >
                {item}
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
      <div className={styles.subtitle}>систем пожарной защиты</div>
    </div>
  );
}

export default Roulette;