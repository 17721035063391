const ServiceAdditionalLine = () => {
  return(
    <svg width="150.000000" height="14.000000" viewBox="0 0 150 14" fill="none"
         xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <radialGradient gradientTransform="rotate(90) scale(7 7)" cx="0.000000" cy="0.000000" r="1.000000"
                        id="paint_radial_36_556_0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA6119"/>
          <stop offset="1.000000" stopColor="#FFFFFF" stopOpacity="0.168627"/>
        </radialGradient>
      </defs>
      <line id="Line 52" x1="144.000000" y1="7.500000" x2="0.000000" y2="7.500000" stroke="#EA6119"
            strokeOpacity="1.000000" strokeWidth="1.000000"/>
      <circle id="Ellipse 25" r="7.000000" transform="matrix(-1 0 0 1 143 7)" fill="url(#paint_radial_36_556_0)"
              fillOpacity="1.000000"/>
    </svg>
  )
}

export default ServiceAdditionalLine;