const FAQMainLine = () => {
  return(
    <svg width="1043.000000" height="15.000000" viewBox="0 0 1043 15" fill="none" xmlns="http://www.w3.org/2000/svg"
         xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <radialGradient gradientTransform="rotate(90) scale(7.5 7.5)" cx="0.000000" cy="0.000000" r="1.000000" id="paint_radial_38_77_0" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EA6119" stopOpacity="0.560784"/>
          <stop offset="1.000000" stopColor="#FFFFFF" stopOpacity="0.168627"/>
        </radialGradient>
      </defs>
      <circle id="Ellipse 18" r="7.500000" transform="matrix(-1 0 0 1 7.5 7.5)" fill="url(#paint_radial_38_77_0)"
              fillOpacity="1.000000"/>
      <line id="Line 45" x1="1043.000000" y1="7.500000" x2="7.000000" y2="7.500000" stroke="#EA6119"
            strokeOpacity="0.500000" strokeWidth="1.000000"/>
    </svg>
  );
}

export default FAQMainLine;